import { useCallback } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import styled from 'styled-components';
import { ParallaxBanner } from 'react-scroll-parallax';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Toast from 'react-bootstrap/Toast';

import useStore from '@stores/useStore';

const Wrap = styled.div`
  position: relative;
  width: 100%;
  background-color: #000;
  padding-top: 60px;
  padding-bottom: 100px;

  .bg-parallax {
    width: 100%;
    height: 100%;
    position: absolute !important;
    top: 0;
    left: 0;
  }
  .row {
    position: relative;
  }
  .flex {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .form-wrap {
    position: relative;
    margin: 0 auto;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 20px;
    max-width: 800px;

    input[type='text'] {
      border-radius: 0;
    }
    .submit {
      width: 100%;
      margin-top: 30px;
    }
    .title {
      margin-top: 20px;
    }
    .col-12 {
      margin: 10px 0;
    }
    .form-check {
      margin-right: 30px;
      margin-bottom: 10px;
    }
    label {
      color: #fff !important;
    }
  }
  .form-check-input.is-valid:checked,
  .was-validated .form-check-input:valid:checked {
    background-color: #0d6efd;
  }
  .form-check-input.is-valid,
  .was-validated .form-check-input:valid {
    border-color: #0d6efd;
  }

  .kakao-btn {
    color: #111;
    background: #fbe300;
    display: flex;
    align-items: center;
    justify-content: center;
    
    img {
      width: 40px;
      margin-right: 5px;
    }
  }

  ${(props) => props.theme.breakpoint('xs', 'xl')`
    padding-left: 20px;
    padding-right: 20px;

    .main-calc h4 {
      font-size: 20px !important;
    }
  `}
`;

const Contact = observer(() => {
  const { commonStore } = useStore();
  const state = useLocalObservable(() => ({
    isNameInvalid: false,
    isPhoneInvalid: false,
    nameFeed: '성명을 입력해 주세요',
    phoneFeed: '연락처를 입력해 주세요',
  }));

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    const btn = document.querySelector('#contact_submit');
    if (btn.disabled) {
      return false;
    }
    btn.disabled = true;
    const form = e.currentTarget;
    state.isNameInvalid = false;
    state.isPhoneInvalid = false;
    const name = form.name.value.replace(/\s/g, '');
    const phone = form.phone.value.replace(/\s/g, '');
    if (!name.length) {
      state.nameFeed = '성명을 입력해 주세요';
      state.isNameInvalid = true;
      btn.disabled = false;
      return;
    }
    if (!/[가-힣]+/.test(name)) {
      state.nameFeed = '한글 성명을 입력해 주세요';
      state.isNameInvalid = true;
      btn.disabled = false;
      return;
    }
    if (!phone.length) {
      state.phoneFeed = '연락처를 입력해 주세요';
      state.isPhoneInvalid = true;
      btn.disabled = false;
      return;
    }
    if (!/010[0-9]{8}/.test(phone)) {
      state.phoneFeed = '핸드폰 번호를 정확하게 입력해 주세요';
      state.isPhoneInvalid = true;
      btn.disabled = false;
      return;
    }
    const data = {
      name,
      phone,
      loss: form.loss.value,
      lossType: form.lossType.value,
      contactTime: form.contactTime.value,
    };
    commonStore.socket.emit('set contact', data);
    Swal.fire({
      title: '문의 완료!',
      html: `${form.name.value}님 문의를 남겨주셔서 감사합니다<br/>전략실에서 확인 후 연락드리겠습니다`,
      icon: 'success',
      confirmButtonText: '확인',
    }).then(() => {
      btn.disabled = false;
      window.location.reload();
    });
  }, []);

  return (
    <Wrap id="contact">
      <ParallaxBanner
        layers={[{ image: '/assets/parallax/bg-parallax2.jpg', speed: 50 }]}
        className="bg-parallax"
      />

      <div className="row">
        <div className="col-md-12">
          <div className="iconbox style_2 mag-top">
            <div className="box-header">
              <div className="box-icon main-calc">
                <h4 className="title desktop" style={{ color: '#fff' }}>
                  <strong>
                    ‘{commonStore.siteMeta.name}’ 와 또 하나의 월급을 만들어
                    보세요
                  </strong>
                </h4>
                <h4 className="title mobile" style={{ color: '#fff' }}>
                  <strong>
                    ‘{commonStore.siteMeta.name}’ 와 또 하나의
                    <br />
                    월급을 만들어 보세요
                  </strong>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Form
        className="form-wrap"
        noValidate
        validated={Boolean(state.isNameInvalid && state.isPhoneInvalid)}
        onSubmit={handleSubmit}
      >
        <Row>
          <Col xs={12}>
            <Form.Group controlId="name">
              <Form.Control
                required
                type="text"
                name="name"
                placeholder="성명"
                isInvalid={state.isNameInvalid}
              />
              <Form.Control.Feedback type="invalid">
                {state.nameFeed}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group controlId="phone">
              <Form.Control
                required
                type="text"
                name="phone"
                placeholder="핸드폰 번호 (-없이 입력)"
                isInvalid={state.isPhoneInvalid}
              />
              <Form.Control.Feedback type="invalid">
                {state.phoneFeed}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12}>
            <h5 className="title" style={{ color: '#fff' }}>
              <strong>손실 금액</strong>
            </h5>
            <div className="flex">
              <Form.Check
                defaultChecked
                id="loss1"
                name="loss"
                type="radio"
                label="100만원"
                value="100만원"
              />
              <Form.Check
                id="loss2"
                name="loss"
                type="radio"
                label="500만원"
                value="500만원"
              />
              <Form.Check
                id="loss3"
                name="loss"
                type="radio"
                label="1000만원"
                value="1000만원"
              />
              <Form.Check
                id="loss4"
                name="loss"
                type="radio"
                label="1500만원 이상"
                value="1500만원 이상"
              />
            </div>
            <h5 className="title" style={{ color: '#fff' }}>
              <strong>손실 종목</strong>
            </h5>
            <div className="flex">
              <Form.Check
                defaultChecked
                id="loss_type1"
                name="lossType"
                type="radio"
                label="국내주식"
                value="국내주식"
              />
              <Form.Check
                id="loss_type2"
                name="lossType"
                type="radio"
                label="미장"
                value="미장"
              />
              <Form.Check
                id="loss_type3"
                name="lossType"
                type="radio"
                label="펀드"
                value="펀드"
              />
              <Form.Check
                id="loss_type4"
                name="lossType"
                type="radio"
                label="코인현물"
                value="코인현물"
              />
              <Form.Check
                id="loss_type5"
                name="lossType"
                type="radio"
                label="코인선물"
                value="코인선물"
              />
              <Form.Check
                id="loss_type6"
                name="lossType"
                type="radio"
                label="해외선물"
                value="해외선물"
              />
            </div>
            <h5 className="title" style={{ color: '#fff' }}>
              <strong>연락 받으실 편한시간</strong>
            </h5>
            <div className="flex">
              <Form.Check
                defaultChecked
                id="contact_time1"
                name="contactTime"
                type="radio"
                label="15:30~16:30"
                value="15:30~16:30"
              />
              <Form.Check
                id="contact_time2"
                name="contactTime"
                type="radio"
                label="16:30~17:30"
                value="16:30~17:30"
              />
              <Form.Check
                id="contact_time3"
                name="contactTime"
                type="radio"
                label="17:30~18:30"
                value="17:30~18:30"
              />
              <Form.Check
                formNoValidate
                id="contact_time4"
                name="contactTime"
                type="radio"
                label="18:30~19:30"
                value="18:30~19:30"
              />
              <Form.Check
                formNoValidate
                id="contact_time5"
                name="contactTime"
                type="radio"
                label="아무때나"
                value="아무때나"
              />
            </div>
          </Col>
          <Col xs={6}>
            <button id="contact_submit" className="button submit" type="submit">
              문의하기
            </button>
          </Col>
          <Col xs={6}>
            <a className="button submit kakao-btn" href="http://pf.kakao.com/_bxbxlTn" target="_blank">
              <img src="/assets/images/social_kakao.png" alt="" />
              <div>실시간 문의하기</div>
            </a>
          </Col>
        </Row>
      </Form>
    </Wrap>
  );
});

export default Contact;
