import Cookies from 'js-cookie';
import { observer } from 'mobx-react';
import { useCallback } from 'react';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';

import useStore from '@stores/useStore';

const Wrap = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #333;
  position: relative;

  label {
    color: #f5f5f5;
  }

  .pwd-wrap {
    width: 400px;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const Login = observer(() => {
  const { commonStore } = useStore();

  const handleChange = useCallback((e) => {
    if (e.target.value == commonStore.pwd) {
      console.log(commonStore.pwd);
      commonStore.isLogin = true;
      Cookies.set('tuja_auth', true);
    } else {
      commonStore.isLogin = false;
      Cookies.set('tuja_auth', false);
    }
  }, []);

  return (
    <Wrap>
      <div className="pwd-wrap">
        <Form.Label htmlFor="tuja_pwd">비밀번호</Form.Label>
        <Form.Control id="tuja_pwd" type="password" onChange={handleChange} />
      </div>
    </Wrap>
  );
});

export default Login;
