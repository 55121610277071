import { useCallback } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import styled from 'styled-components';
import { ParallaxBanner } from 'react-scroll-parallax';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import dayjs from 'dayjs';
import Badge from 'react-bootstrap/Badge';

import useStore from '@stores/useStore';

import { numberWithCommas } from '@utils/common';

const Wrap = styled.div`
  position: relative;
  width: 100%;
  background-color: #000;
  padding-bottom: 120px;

  .bg-parallax {
    width: 100%;
    height: 100%;
    position: absolute !important;
    top: 0;
    left: 0;
  }
  .row {
    position: relative;
  }
  .info {
    padding: 30px;
  }
  .desc,
  .mag-top {
    word-break: keep-all;
  }
  .arrow-bottom {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
  .calc-wrap {
    position: relative;
    margin: 0 auto;
    max-width: 600px;
    display: flex;
    justify-content: center;
    align-items: center;

    input {
      border-radius: 0;
    }
  }
  svg {
    path {
      stroke: #fff;
    }
  }

  ${(props) => props.theme.breakpoint('xs', 'xl')` 
  padding: 0 20px;   
  padding-bottom: 100px;
  .desc h5 {
    font-size: 16px;
  }
  .calc-wrap {
    flex-direction: column;
  }
  input::placeholder {
    font-size: 14px;
  }
  .button {
    width: 100%;
  }
  `}
`;

const Section1 = observer(() => {
  const { commonStore } = useStore();
  const state = useLocalObservable(() => ({
    per: 2,
    amount: '',
    show: false,

    spinnerShow: false,
    calcAmount: '',
    calcPer: '',
    date: '',
  }));

  const handleClose = useCallback(() => {
    state.show = false;
  }, []);

  const handleChange = useCallback((e) => {
    state.amount = numberWithCommas(e.target.value.replace(/[^\d]/g, ''), true);
  }, []);

  const handleCalc = useCallback((e) => {
    let amount = parseInt(state.amount.replace(/[^\d]/g, ''));
    if (!amount || isNaN(amount)) {
      Swal.fire({
        html: `투자금을 입력해 주세요`,
        icon: 'error',
        confirmButtonText: '확인',
      });
      return;
    }
    let calcAmount = amount;

    for (var i = 0; i < 12; i++) {
      calcAmount += Math.floor(
        calcAmount * (commonStore.siteMeta.calc_per / 100),
      );
    }
    state.calcPer = (((calcAmount - amount) / amount) * 100).toFixed(2);
    state.calcAmount = calcAmount;
    state.date = dayjs().add(1, 'year').format('YYYY년 M월 D일');
    state.spinnerShow = true;
    state.show = true;
    setTimeout(() => {
      state.spinnerShow = false;
    }, 1500);
  }, []);

  return (
    <Wrap>
      <Modal
        dialogClassName="calc-dialog"
        contentClassName="calc-content"
        show={state.show}
        onHide={handleClose}
      >
        <Modal.Body>
          {state.spinnerShow ? (
            <div className="spinner">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <div className="result-calc">
              <div className="date">
                금일부터 12개월 이용 시 {commonStore.screen.md ? '' : <br />}
                <span>{state.date}</span> 까지
              </div>
              <div className="calc-per">
                수익률{' '}
                <Badge bg="danger">
                  <i className="fa fa-arrow-up" />
                  &nbsp;&nbsp;{parseFloat(state.calcPer).toFixed(2)}%
                </Badge>
              </div>
              <div className="calc-amount">
                원금포함 <span>{numberWithCommas(state.calcAmount)}원</span>{' '}
                이익
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>

      <ParallaxBanner
        layers={[{ image: '/assets/parallax/bg-parallax1.jpg', speed: 50 }]}
        className="bg-parallax"
      />

      <div className="row info">
        <div className="col-md-4">
          <div className="iconbox style_1 mag-top">
            <div className="box-header">
              <div className="box-icon">
                <i className="fa fa-certificate"></i>
              </div>

              <h3 className="box-title color-ffffff">FSS REGISTRATION</h3>
            </div>
            <div className="box-content">
              <h4 className="color-ffffff">
                <strong>금융감독원 공식신고</strong>
              </h4>
              <span>
                금융감독원 공식 신고를
                <br />
                통한 합법적 기업 등록,
                <br />
                신뢰를 바탕으로
                <br />
                고객님을 책임집니다.
              </span>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="iconbox style_1 mag-top">
            <div className="box-header">
              <div className="box-icon">
                <i className="fa fa-graduation-cap"></i>
              </div>
              <h3 className="box-title color-ffffff">LAWYER’S CERTIFICATION</h3>
            </div>
            <div className="box-content">
              <h4 className="color-ffffff">
                <strong>변호사 인증</strong>
              </h4>
              <span>
                변호사가 인정한
                <br />
                {commonStore.siteMeta.name} 수익률.
                <br />
                {commonStore.siteMeta.name}는
                <br />
                주식거래 수익률을
                <br />
                투명하게 보여드립니다.
              </span>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="iconbox style_1 mag-top">
            <div className="box-header">
              <div className="box-icon">
                <i className="fa fa-ban"></i>
              </div>
              <h3 className="box-title color-ffffff">NO FALSE AD</h3>
            </div>
            <div className="box-content">
              <h4 className="color-ffffff">
                <strong>허위과장광고는 절대 NO!</strong>
              </h4>
              <span>
                거짓없는 실수익 후기.
                <br />
                실계좌를 오픈한 VIP의
                <br />
                실제후기를 보여드립니다.
                <br />
                고객님께 절대 허위 과장광고를
                <br />
                하지 않습니다.
              </span>
            </div>
          </div>
        </div>

        {/* <div className="col-md-3">
          <div className="iconbox style_1 highlight main-1">
            <div className="box-content">
              <a
                href="<?php echo site_url('document/aboutvip') ?>"
                className="button style_1 highlight"
              >
                나의 투자성향 확인하기
              </a>
            </div>
          </div>
        </div> */}
      </div>

      {/* <div className="row">
        <div className="col-md-12">
          <div className="iconbox style_2 mag-top">
            <div className="box-header">
              <div className="box-icon main-calc">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 24 24"
                >
                  <path d="M12.611 13.663c.262-.187.559-.274.849-.274.616 0 1.21.392 1.405 1.044-.249-.191-.541-.285-.836-.285-.301 0-.603.097-.866.285-.522.374-.753 1.009-.551 1.611-.814-.581-.819-1.795-.001-2.381zm2.073 7.831c.651.218 2.665.772 4.999 2.506l4.317-3.088c-1.123-1.569-.816-2.669-1.932-4.229-.499-.695-.939-1.12-1.755-.977l-.234.043.394.548c.239.335-.267.683-.499.357l-.351-.49c-.124-.174-.34-.256-.548-.21l-.796.179.478.666c.24.336-.267.681-.499.356l-.412-.576c-.129-.18-.353-.26-.562-.208l-.809.203.504.705c.241.336-.267.682-.499.357l-1.658-2.334c-.269-.376-.793-.463-1.17-.194-.376.27-.464.793-.193 1.17l2.632 3.7c-.812-.299-2.059-.426-2.289.411-.139.501.262.898.882 1.105zm-.684-18.494h-11v5h11v-5zm-7 9h3v-2h-3v2zm-1-2h-3v2h3v-2zm0 3h-3v2h3v-2zm-3 5h3v-2h-3v2zm7-5h-3v2h3v-2zm2.306 6h-10.306v-17h13v9.75c1.487.733 2 2.546 2 2.546v-14.296h-17v21h11.821c-.128-.802.049-1.379.485-2zm-1.306-9v2h.507c.709-.486 1.569-.711 2.493-.568v-1.432h-3zm-1 6h-3v2h3v-2z" />
                </svg>
                &nbsp;
                <h4 className="title" style={{ color: '#fff' }}>
                  <strong>
                    ‘{commonStore.siteMeta.name}’ 와 함께 상위 1% 프로젝트에
                    참여했다면? 예상 수익 확인 시뮬레이터
                  </strong>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="calc-wrap">
        <Form.Control
          type="text"
          placeholder="투자금을 입력하세요(숫자만 입력)"
          value={state.amount}
          onChange={handleChange}
        />
        <button className="button" onClick={handleCalc}>
          수익금 확인하기
        </button>
      </div> */}

      <div className="row">
        <div className="col-md-12">
          <div className="flat-border-bottom">
            <span className="sep-holder">
              <span className="sep-line"></span>
            </span>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="title-section">
            <div className="desc color-style_1">
              <h5 className="color-ffffff">
                {commonStore.siteMeta.name}와 12개월 동안 함께했을 때 실제
                수익낸 순수익률 값을 월복리 데이터화 한 예상 수익금{' '}
              </h5>
              <h5 className="color-ffffff">
                (원금+이자) + 이자 복리가 가져다 주는 마법 효과를 ‘
                {commonStore.siteMeta.name}’와 함께 하세요!
              </h5>
            </div>
          </div>
        </div>
      </div>

      <img className="arrow-bottom" src="/assets/arrow-bottom.png" alt="" />
    </Wrap>
  );
});

export default Section1;
