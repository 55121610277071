import { useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { ParallaxProvider } from 'react-scroll-parallax';
import { Helmet } from 'react-helmet';

import useStore from '@stores/useStore';

import Banner from './Banner';
import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';
import Section4 from './Section4';
import Section5 from './Section5';
import Section6 from './Section6';
import Section7 from './Section7';
import Contact from './Contact';
import Footer from './Footer';

const Wrap = styled.div`
  width: 100%;

  .content-wrap {
    max-width: 1100px;
    margin: 0 auto;
    padding: 40px 0;
  }

  ${(props) => props.theme.breakpoint('xs', 'xl')`
  .flat-border-bottom {
    margin: 30px 0;
  }
  .content-wrap {
    padding: 40px 20px;
  }
  `}
`;

const Main = observer(() => {
  const { commonStore } = useStore();

  const handleResize = useCallback(() => {
    commonStore.screen.sm = false;
    commonStore.screen.md = false;
    commonStore.screen.lg = false;
    commonStore.screen.xl = false;
    commonStore.screen.xxl = false;

    if (window.innerWidth >= 0) {
      commonStore.screen.xs = true;
    }
    if (window.innerWidth >= 576) {
      commonStore.screen.sm = true;
    }
    if (window.innerWidth >= 768) {
      commonStore.screen.md = true;
    }
    if (window.innerWidth >= 992) {
      commonStore.screen.lg = true;
    }
    if (window.innerWidth >= 1200) {
      commonStore.screen.xl = true;
    }
    if (window.innerWidth >= 1600) {
      commonStore.screen.xxl = true;
    }
  }, []);

  useEffect(() => {
    document.querySelector('body').className = 'client';
    commonStore.connectSocket();

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <ParallaxProvider
      scrollContainer={document ? document.querySelector('body') : null}
    >
      <Wrap>
        <Helmet>
          <meta name="description" content={commonStore.siteMeta.description} />
          <title>{commonStore.siteMeta.name}</title>
        </Helmet>

        <Banner />
        <Section1 />
        <Section2 />

        <div className="content-wrap">
          <Section3 />
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="flat-border-bottom">
              <span className="sep-holder">
                <span className="sep-line-black"></span>
              </span>
            </div>
          </div>
        </div>

        <Contact />

        <div className="row">
          <div className="col-md-12">
            <div className="flat-border-bottom">
              <span className="sep-holder">
                <span className="sep-line-black"></span>
              </span>
            </div>
          </div>
        </div>

        <div className="content-wrap">
          <Section4 />
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="flat-border-bottom">
              <span className="sep-holder">
                <span className="sep-line-black"></span>
              </span>
            </div>
          </div>
        </div>

        <div className="content-wrap">
          <Section5 />
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="flat-border-bottom">
              <span className="sep-holder">
                <span className="sep-line-black"></span>
              </span>
            </div>
          </div>
        </div>

        <div className="content-wrap">
          <Section6 />
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="flat-border-bottom">
              <span className="sep-holder">
                <span className="sep-line-black"></span>
              </span>
            </div>
          </div>
        </div>

        <div className="content-wrap">
          <Section7 />
        </div>
        
        <Footer />
      </Wrap>
    </ParallaxProvider>
  );
});

export default Main;
